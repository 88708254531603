// LandingPage.js
import React, { useState, useRef } from 'react';
import './LandingPage.css'
import { Link } from 'react-router-dom';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import Navbar from './Navbar';
import Footer from './Footer';
import api from '../api';
import Toast from '../UI/Toast';
import ReCAPTCHA from 'react-google-recaptcha';
import LoadingButton from '../UI/LoadingButton';

const LandingPage = () => {

    const recaptchaRef = useRef();
    const [openIndex, setOpenIndex] = useState(null);
    const [toast, setToast] = useState({ show: false, type: '', message: '' });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isProcessingCheckout, setIsProcessingCheckout] = useState(false);

    const togglePanel = (index) => {
        if (openIndex === index) {
            setOpenIndex(null);
        } else {
            setOpenIndex(index);
        }
    };

    const handleEbookSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const email = e.target.email.value;
            const response = await api.post('/ebook_optin', { email });

            if (response.data.status === 'success') {
                setToast({
                    show: true,
                    type: 'success',
                    message: response.data.message
                });
                e.target.reset();
            }
        } catch (error) {
            setToast({
                show: true,
                type: 'error',
                message: error.response?.data?.message || 'An error occurred. Please try again.'
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleCheckout = async () => {
        try {
            setIsProcessingCheckout(true);
            
            // Définir votre identifiant de prix Stripe
            const stripePriceId = process.env.REACT_APP_LTD_PRICE_ID; // STANDALONE_PRICE_ID from config
            
            // Faire la requête à l'API pour créer une session de paiement
            const response = await api.post(`/checkout/${stripePriceId}`);
            
            // Récupérer l'URL de redirection depuis la réponse
            const checkoutUrl = response.data.checkout_url;
            
            // Rediriger vers l'URL de checkout Stripe
            window.location.href = checkoutUrl;
        } catch (error) {
            console.error('Erreur lors de la création de la session de paiement:', error);
            
            setToast({
                show: true,
                type: 'error',
                message: "Une erreur s'est produite lors de la préparation de votre paiement. Veuillez réessayer."
            });
            
            setIsProcessingCheckout(false);
        }
    };

    return (
        <>
                <Navbar />

            <div className='lp'>

                    <div className="section hero-section">
                        <div className="hero-content">
                        <h2>A Secret <span className="lp-highlight">YouTube Strategy</span> Allow You To</h2>

                        <h1>Convert Any Video Into Multiple Revenue-Generating Content Assets <span className="lp-badge">In Seconds</span></h1>
                        <h4>The Smart Content Creation System That Eliminates Hours Of Work & Expensive Subscriptions While <span className="lp-underline">Delivering Better Results</span></h4>
                        <p>
                            <span className="lp-header-badge">✓ Save $1,000+ On Content Creation</span>
                            <span className="lp-header-badge">✓ Works In Any Language</span>
                            <span className="lp-header-badge">✓ No Technical Skills Needed</span>
                            <span className="lp-header-badge">✓ Unlimited Commercial Use</span>
                        </p>
                        <Link to="/app" className="no-style-link">
                            <button className="button-important">
                                <div className="inner">
                                    GET STARTED NOW
                                </div>
                            </button>
                        </Link>
                    </div>

                    <hr />

                    {/* <div className="trusted-by-section">
                            <div className="trusted-by-carousel">
                                <div className="carousel-container">
                                    <div className="carousel-track">
                                        <div className="carousel-item">
                                            <a href="https://theresanaiforthat.com/ai/yt-copycat/?ref=featured&v=139162"
                                                target="_blank"
                                                rel="nofollow">
                                                <img
                                                    width="300"
                                                    src="https://media.theresanaiforthat.com/featured-on-taaft.webp?width=600"
                                                    alt="Featured on There's An AI For That"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                </div>

                <div className="section">
                    <div className="section-header">
                        <h2>Leverage the <span className="lp-badge">Hidden Potential</span> Behind Every YouTube Video to Build Your Content Empire</h2>
                    </div>


                    <div className="lp-grid">
                        <div className="lp-col">

                            <p>Transform ANY YouTube video into multiple high-converting content formats.</p>
                            <p>While others struggle with expensive writers, complex tools, and content blocks, you can take advantage of this opportunity.</p>
                            <p>Top content creators are already using this method to dominate their niches.</p>
                            <p>Now it's your turn to join them and elevate your content creation game.</p>
                        </div>
                        <div className="lp-col">
                            <video muted autoPlay loop src="/demo.mp4" className="hero-video glowAnime"></video>
                        </div>

                    </div>
                </div>

                <div className="section">
                    <div className="section-header">
                        <h2>Why Most Content Creators <span className="lp-highlight">Struggle</span></h2>
                    </div>



                    <div className="lp-grid">
                        <div className="lp-col">
                            <img className='lp-img-fluid lp-centered-img' src="/images/lp/depressed.webp" alt="struggle" />
                        </div>
                        <div className="lp-col">
                            <p>Creating quality content isn't just time-consuming - it's EXPENSIVE. Consider what you're currently facing:</p>
                            <ul className="lp-list lp-list-cross">
                                <li className="lp-list-item">Content Writers: $100-200 per article</li>
                                <li className="lp-list-item">SEO Services: $499-999 per month</li>
                                <li className="lp-list-item">Content Tools: $49-99 per month, EACH</li>
                                <li className="lp-list-item">Video Production: $300+ per video</li>
                            </ul>
                            <p>The average content creator spends over $1,000 monthly just to stay competitive. All while battling content blocks, inconsistent quality, and diminishing returns.</p>
                            <p>But what if there was a better way?</p>
                        </div>

                    </div>
                </div>

                <div className="section">
                    <div className="section-header">
                        <h2>Introducing <span className="lp-badge">YT Copycat</span> - Content Creation Reimagined</h2>
                    </div>



                    <div className="lp-grid">
                        <div className="lp-col">

                            <p>YT Copycat harnesses the power of advanced AI to transform ANY YouTube video into multiple high-converting content assets in seconds.</p>
                            <p>This process does not require any technical skills, video editing experience, or the need to appear on camera.</p>
                            <p>With our proprietary conversion technology, you can leverage YouTube's massive content library.</p>
                            <p>Think of it as your personal content factory, enabling you to create unique, plagiarism-free content that converts effectively.</p>
                            <Link to="/app" className="no-style-link">
                                <button className="button-important">
                                    <div className="inner">
                                        UNLOCK UNLIMITED CONTENT NOW
                                    </div>
                                </button>
                            </Link>
                        </div>
                        <div className="lp-col">
                            <img className='lp-img-fluid lp-centered-img' src="/images/lp/yt_to_content.webp" alt="yt_to_content" />
                        </div>

                    </div>
                        </div>


                        <div className="section styles-section">
                            <div className="section-header">
                                <h4>FORMATS</h4>
                        <h2>Transform YouTube Videos Into <span className="lp-highlight">Multiple High-Value Formats</span></h2>
                                <p>One video, endless possibilities. Generate various types of content with just a few clicks.</p>
                            </div>

                            <div className="styles-grid">
                                <div className="style-card">
                                    <div className="style-icon">
                                        <i className="fas fa-newspaper"></i>
                                    </div>
                            <h3>Blog Articles</h3>
                            <p>Convert videos into SEO-optimized blog posts that rank well and drive organic traffic to your site. Each article maintains a unique voice while capturing the essence of the original content.</p>
                                </div>

                                <div className="style-card">
                                    <div className="style-icon">
                                        <i className="fab fa-twitter"></i>
                                    </div>
                            <h3>Twitter/X Threads</h3>
                            <p>Transform long videos into engaging social threads that generate engagement and followers. Perfect for building your personal brand or client accounts.</p>
                                </div>

                                <div className="style-card">
                                    <div className="style-icon">
                                        <i className="fas fa-envelope-open-text"></i>
                                    </div>
                            <h3>Professional Newsletters</h3>
                            <p>Create email-ready content that nurtures relationships with subscribers and drives conversions. Never struggle with "what to send" again.</p>
                                </div>

                                <div className="style-card">
                                    <div className="style-icon">
                                        <i className="fab fa-linkedin"></i>
                                    </div>
                            <h3>LinkedIn Posts</h3>
                            <p>Generate professional LinkedIn content with perfect formatting to establish thought leadership and attract high-value connections.</p>
                                </div>

                                <div className="style-card">
                                    <div className="style-icon">
                                        <i className="fas fa-list-check"></i>
                                    </div>
                            <h3>Concise Summaries</h3>
                            <p>Create bullet-point summaries for quick consumption, perfect for busy executives or as lead magnets for your audience.</p>
                                </div>

                                <div className="style-card">
                                    <div className="style-icon">
                                        <i className="fas fa-pen-fancy"></i>
                                    </div>
                            <h3>Custom Formats</h3>
                            <p>Design your own output formats with our flexible template system to meet any content need for yourself or clients.</p>
                        </div>
                    </div>
                </div>

                <div className="section">
                    <div className="lp-grid">
                        <div className="lp-col">
                            <img src="/images/lp/steps.webp" alt="YT Copycat creativity" className="lp-img-fluid" />
                        </div>
                        <div className="lp-col">
                            <div className='section-header'>
                                <h4>PROCESS</h4>
                                <h2>
                                    Three Simple Steps to <span className="lp-badge">Content Mastery</span>
                                </h2>
                            </div>
                            <div className="step">
                                <h3 className="step-number">01</h3>
                                <div className="step-details">
                                    <h4 className="step-title">Enter YouTube URL</h4>
                                    <p className="step-description">Simply paste any YouTube video URL into the intuitive dashboard. Our system immediately begins analyzing the content.</p>
                                </div>
                            </div>
                            <div className="step">
                                <h3 className="step-number">02</h3>
                                <div className="step-details">
                                    <h4 className="step-title">Select Your Format</h4>
                                    <p className="step-description">
                                        Choose from multiple content formats or create your own custom template to match exactly what you need.
                                    </p>
                                        </div>
                                    </div>
                            <div className="step">
                                <h3 className="step-number">03</h3>
                                <div className="step-details">
                                    <h4 className="step-title">Generate & Deploy</h4>
                                    <p className="step-description">
                                        With one click, transform the video into high-converting content assets ready to publish, customize, or sell to clients.
                                    </p>
                                </div>
                            </div>
                            <Link to="/app" className="no-style-link">
                                <button className="button-important">
                                    <div className="inner">
                                        START CREATING CONTENT NOW
                                    </div>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>

                    <div className="section features-section" id="features">
                        <div className="section-header">
                            <h4>FEATURES</h4>
                        <h2>The Powerful Engine Behind Your <span className="lp-highlight">Content Empire</span></h2>
                            <p>
                            Cutting-edge technology that transforms how you create and distribute content
                            </p>
                        </div>

                        <div className="features-grid">
                            <div className="feature-item">
                                <div className="icon">
                                    <i className="fas fa-wand-magic-sparkles"></i>
                                </div>
                                <div>
                                <h4>AI-Powered Content Transformation</h4>
                                <p>Our system uses advanced GPT-4 technology to transform video content into high-quality written assets while maintaining your unique voice and style preferences.</p>
                                </div>
                            </div>

                            <div className="feature-item">
                                <div className="icon">
                                    <i className="fas fa-language"></i>
                                </div>
                                <div>
                                    <h4>Multilingual Support</h4>
                                <p>Generate content in 10+ languages to reach global audiences with perfect native translations - without hiring expensive translators.</p>
                                </div>
                            </div>

                            <div className="feature-item">
                                <div className="icon">
                                    <i className="fas fa-bolt"></i>
                                </div>
                                <div>
                                <h4>Lightning-Fast Processing</h4>
                                <p>Get quality content in seconds, regardless of video length. What used to take hours now happens almost instantly.</p>
                                </div>
                            </div>

                            <div className="feature-item">
                                <div className="icon">
                                    <i className="fas fa-shield-halved"></i>
                                </div>
                                <div>
                                <h4>100% Unique Content Every Time</h4>
                                <p>Every piece of content is plagiarism-free and SEO-optimized to rank well on search engines and pass AI detection tools.</p>
                                </div>
                            </div>

                            <div className="feature-item">
                                <div className="icon">
                                    <i className="fas fa-sliders"></i>
                                </div>
                                <div>
                                    <h4>Advanced Customization</h4>
                                <p>Fine-tune tone, style, and format to match your brand's voice perfectly or adapt to different client requirements.</p>
                        </div>
                    </div>

                        <div className="feature-item">
                            <div className="icon">
                                <i className="fas fa-copyright"></i>
                            </div>
                            <div>
                                <h4>Commercial Rights Included</h4>
                                <p>Use YT Copycat for client work with no restrictions - charge premium rates for content services without additional fees.</p>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="section">
                    <div className="section-header">
                        <h2>The <span className="lp-highlight">YT Copycat</span> Advantage</h2>

                                </div>

                    <div className="lp-grid">
                        <div className="lp-col">
                            <div className="lp-comparison lp-comparison-negative">
                                <h3>WITHOUT YT Copycat:</h3>
                                <ul className="lp-list lp-list-cross">
                                    <li className="lp-list-item">Spend $1,000+ monthly on content creation tools</li>
                                    <li className="lp-list-item">Wait days for content delivery from writers</li>
                                    <li className="lp-list-item">Struggle with inconsistent quality and tone</li>
                                    <li className="lp-list-item">Limited to creating one content type at a time</li>
                                    <li className="lp-list-item">Restricted by budget constraints</li>
                                    <li className="lp-list-item">Constant content blocks and idea shortages</li>
                                </ul>
                            </div>
                        </div>
                        <div className="lp-col">
                            <div className="lp-comparison lp-comparison-positive">
                                <h3>WITH YT Copycat:</h3>
                                <ul className="lp-list lp-list-check">
                                    <li className="lp-list-item">Create unlimited content with one investment</li>
                                    <li className="lp-list-item">Generate new content assets in seconds</li>
                                    <li className="lp-list-item">Maintain consistent quality across all platforms</li>
                                    <li className="lp-list-item">Transform one video into multiple content formats</li>
                                    <li className="lp-list-item">Scale your content operation without scaling costs</li>
                                    <li className="lp-list-item">Never face a content block again</li>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section reviews-section" id="reviews">
                        <div className="section-header">
                        <h2>What <span className="lp-badge">Content Creators</span> Are Saying</h2>
                        <p>These reviews were sourced from the YT Copycat <a href="https://appsumo.com/products/yt-copycat/reviews/?page=1&sort=rating%3Adesc">AppSumo page</a>.</p>
                        </div>

                        <div className="reviews-grid">
                            <div className="review-item">
                                <div className="review-header">
                                <span>creacendo ⭐⭐⭐⭐⭐</span>
                                </div>
                            <div className="review-tagline">VERY WELL WORTH IT!</div>
                                <div className="review-content">
                                "This is a great addition to your tools as far as content writing is concerned. I like how the interface is easy to use, online, keeps records of your work and generates the content fast off a youtube video. I've tried other plugins off the chatgpt store and this one is the best."
                                </div>
                            </div>

                            <div className="review-item">
                                <div className="review-header">
                                <span>Bob ⭐⭐⭐⭐⭐</span>
                                </div>
                            <div className="review-tagline">Great Product For The Price!!!</div>
                                <div className="review-content">
                                "I've been using this tool regularly on informational YouTube videos, and it consistently provides perfect blog prompts. Exactly how I like it. After several months of use, my content is now ranking high in SEO terms on Neuronwriter. The two combined are a perfect pair."
                                </div>
                            </div>

                            <div className="review-item">
                                <div className="review-header">
                                <span>TheInfoGuy ⭐⭐⭐⭐⭐</span>
                                </div>
                            <div className="review-tagline">Terrific Tool, a must have</div>
                                <div className="review-content">
                                "I used other ChatGPT video to text tools for months, and always spent lots of time correcting things like rephrasing, niche correcting, etc. Since switching to YT Copycat, I'm amazed how even the meta and keywords are there... The output might be concise, but it's very easy to augment and perfect for my needs!"
                                </div>
                            </div>

                            <div className="review-item">
                                <div className="review-header">
                                <span>lucas782 ⭐⭐⭐⭐⭐</span>
                            </div>
                            <div className="review-tagline">Fantastic content writing tool!</div>
                            <div className="review-content">
                                "I hardly ever write reviews, but this one deserves it. The interface is user-friendly and online, it tracks your work history, and it quickly generates content based on YouTube videos. After extensive use, I can confidently say this is by far the best among all plugins from the ChatGPT store."
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section">
                    <div className="section-header">
                        <h2>Who Benefits From <span className="lp-badge">YT Copycat</span>?</h2>
                        <p >Whether you're just starting your content journey or running a full-scale operation, YT Copycat gives you the edge in today's competitive digital landscape.</p>
                    </div>

                    <div className="lp-users-grid">
                        <div className="lp-user-card">
                            <div className="lp-user-image">
                                <img src="/images/lp/content_creator.webp" alt="Content Creator" className="lp-img-fluid" />
                            </div>
                            <h3>Content Creators</h3>
                            <p>Scale your production without scaling your time investment</p>
                        </div>

                        <div className="lp-user-card">
                            <div className="lp-user-image">
                                <img src="/images/lp/digital_marketer.webp" alt="Digital Marketer" className="lp-img-fluid" />
                            </div>
                            <h3>Digital Marketers</h3>
                            <p>Create content for multiple channels from one source</p>
                        </div>

                        <div className="lp-user-card">
                            <div className="lp-user-image">
                                <img src="/images/lp/agency.webp" alt="Agency" className="lp-img-fluid" />
                            </div>
                            <h3>Agencies</h3>
                            <p>Offer premium content services without premium costs</p>
                        </div>

                        <div className="lp-user-card">
                            <div className="lp-user-image">
                                <img src="/images/lp/freelance.webp" alt="Freelancer" className="lp-img-fluid" />
                            </div>
                            <h3>Freelancers</h3>
                            <p>Add high-value content services to your offering</p>
                        </div>

                        <div className="lp-user-card">
                            <div className="lp-user-image">
                                <img src="/images/lp/business.webp" alt="Business" className="lp-img-fluid" />
                            </div>
                            <h3>Businesses</h3>
                            <p>Keep your content fresh with minimal effort</p>
                        </div>

                        <div className="lp-user-card">
                            <div className="lp-user-image">
                                <img src="/images/lp/videaste.webp" alt="Influencer" className="lp-img-fluid" />
                            </div>
                            <h3>Influencers</h3>
                            <p>Repurpose your video content across all platforms</p>
                        </div>

                    </div>


                </div>

                <div className="section">
                    <div className="lp-grid">
                        <div className="lp-col">
                            <img src="/images/lp/compo.webp" alt="Premium Content System" className="lp-img-fluid" />
                        </div>
                        <div className="lp-col">
                            <div className="section-header">
                                <h2><span className="lp-badge">Premium Content</span> System At An Unbeatable Value</h2>
                                <h4>What's The True Value of YT Copycat?</h4>
                            </div>

                            <p>Before answering, consider what you're receiving:</p>

                            <ul className="lp-list lp-list-check" style={{ maxWidth: "500px", margin: "20px auto" }}>
                                <li className="lp-list-item">A complete content transformation system</li>
                                <li className="lp-list-item">Technology that replaces multiple expensive subscriptions</li>
                                <li className="lp-list-item">Commercial rights to use for client services</li>
                                <li className="lp-list-item">The ability to create unlimited content assets</li>
                                <li className="lp-list-item">Ongoing updates and improvements</li>
                            </ul>

                            <p>If you hired writers for just 3 articles monthly, you'd spend over $3,600 per year. Content tools alone could cost you $1,200+ annually.</p>
                            <p>YT Copycat delivers more value at a fraction of these costs.</p>

                        </div>
                    </div>
                </div>

                <div className="section" id="pricing">
                    <div className="lp-grid">

                        <div className="lp-col">


                            <h2>Special Limited-Time Offer</h2>
                            <div className="price-tag">
                                <span className="old-price">$297</span>
                                $197
                                <span className="discount-label">33% OFF</span>
                            </div>

                            <div className="guarantee-box">
                                <div className="guarantee-icon">
                                    <i className="fas fa-shield-check"></i>
                                </div>
                                <div className="guarantee-content">
                                    <h3>Our Risk-Free Guarantee</h3>
                                    <p>Try YT Copycat for a full 30 days. If it doesn't revolutionize your content creation process, we'll refund every penny. No questions asked. No complex returns process. Your satisfaction is our priority.</p>
                                </div>
                            </div>

                            <p className="urgency-message">This special pricing won't last forever. Once this launch period ends, the price returns to the regular $297, with no exceptions.</p>

                            <h4>For a limited time: Get YT Copycat for just $197 (33% OFF the regular price of $297)</h4>

                            <button className="button-important" onClick={handleCheckout} disabled={isProcessingCheckout}>
                                <div className="inner">
                                    {isProcessingCheckout ? 'PROCESSING PAYMENT...' : 'SECURE YOUR DISCOUNT NOW'}
                                </div>
                            </button>

                        </div>
                        <div className="lp-col">
                            <img src="/images/lp/compo_2.webp" alt="Premium Content System" className="lp-img-fluid" />
                            </div>
                        </div>
                    </div>

                <div className="section faq-section" id="faq">
                        <div className="section-header">
                        <h2>Common <span className="highlight">Questions</span> About YT Copycat</h2>
                        </div>

                        <div className="faq-grid">
                            <div className="faq-item">
                                <div className="faq-question" onClick={() => togglePanel(0)}>
                                    <div className="question-content">
                                    <i className="fas fa-fingerprint"></i>
                                    <span>Is the content unique and plagiarism-free?</span>
                                    </div>
                                    <i className={`fas ${openIndex === 0 ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                                </div>
                                {openIndex === 0 && (
                                    <div className="faq-answer">
                                    Absolutely. Our advanced AI creates original content that passes plagiarism checks while maintaining the core information from the video. The system doesn't copy or republish video content directly but creates entirely new, original written content based on the concepts presented in the video. This falls within fair use guidelines as it transforms the content into a different medium while adding value through AI enhancement and reorganization.
                                    </div>
                                )}
                            </div>

                            <div className="faq-item">
                                <div className="faq-question" onClick={() => togglePanel(1)}>
                                    <div className="question-content">
                                    <i className="fas fa-code"></i>
                                    <span>Do I need technical skills to use YT Copycat?</span>
                                    </div>
                                    <i className={`fas ${openIndex === 1 ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                                </div>
                                {openIndex === 1 && (
                                    <div className="faq-answer">
                                    None whatsoever! If you can copy and paste a URL, you can use our tool. The intuitive interface is designed for anyone to use without technical knowledge. Simply paste the YouTube URL, choose your desired output format, adjust any preferences if desired, and receive your professionally formatted content in seconds.
                                    </div>
                                )}
                            </div>

                            <div className="faq-item">
                                <div className="faq-question" onClick={() => togglePanel(2)}>
                                    <div className="question-content">
                                    <i className="fas fa-copyright"></i>
                                    <span>Can I use the content for commercial purposes?</span>
                                    </div>
                                    <i className={`fas ${openIndex === 2 ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                                </div>
                                {openIndex === 2 && (
                                    <div className="faq-answer">
                                    Absolutely! Your subscription includes full commercial rights. The content is 100% yours to monetize through various channels such as advertising, affiliate marketing, promoting your products or services, building email subscriber lists, or offering content creation services to clients (covered under your commercial license).
                                    </div>
                                )}
                            </div>

                            <div className="faq-item">
                                <div className="faq-question" onClick={() => togglePanel(3)}>
                                    <div className="question-content">
                                    <i className="fas fa-chart-bar"></i>
                                    <span>How many content pieces can I create?</span>
                                    </div>
                                    <i className={`fas ${openIndex === 3 ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                                </div>
                                {openIndex === 3 && (
                                    <div className="faq-answer">
                                    There are no artificial restrictions! You can process as many videos as you need and create as much content as you want. The only practical limitation is your OpenAI API usage, which you manage directly through your own account.
                                    </div>
                                )}
                            </div>

                            <div className="faq-item">
                                <div className="faq-question" onClick={() => togglePanel(4)}>
                                    <div className="question-content">
                                    <i className="fas fa-globe"></i>
                                    <span>Does it work in languages other than English?</span>
                                    </div>
                                    <i className={`fas ${openIndex === 4 ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                                </div>
                                {openIndex === 4 && (
                                    <div className="faq-answer">
                                    Yes! YT Copycat supports more than 10 languages, including English, Spanish, French, German, Italian, Portuguese, Dutch, Russian, Japanese, and Chinese. This multilingual capability allows you to create content for diverse international audiences with native-quality output.
                                    </div>
                                )}
                            </div>

                            <div className="faq-item">
                                <div className="faq-question" onClick={() => togglePanel(5)}>
                                    <div className="question-content">
                                    <i className="fas fa-key"></i>
                                    <span>Do I need to provide my own OpenAI API key?</span>
                                    </div>
                                    <i className={`fas ${openIndex === 5 ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                                </div>
                                {openIndex === 5 && (
                                    <div className="faq-answer">
                                    Yes, YT Copycat requires your own OpenAI API key. This approach helps us maintain affordable pricing while giving you complete control over your API usage. Don't worry – we provide comprehensive step-by-step instructions for setting up your API key quickly and easily within our members area.
                                </div>
                            )}
                        </div>

                        <div className="faq-item">
                            <div className="faq-question" onClick={() => togglePanel(6)}>
                                <div className="question-content">
                                    <i className="fas fa-credit-card"></i>
                                    <span>Are there additional costs for using YT Copycat?</span>
                                </div>
                                <i className={`fas ${openIndex === 6 ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                            </div>
                            {openIndex === 6 && (
                                <div className="faq-answer">
                                    The only additional cost is your OpenAI API usage. Most users typically spend between $5-20 monthly for regular content creation. A standard blog post might cost approximately $0.10-0.30 in API credits. This pay-as-you-go approach ensures you only pay for your actual usage rather than expensive flat-rate subscriptions.
                                </div>
                            )}
                        </div>

                        <div className="faq-item">
                            <div className="faq-question" onClick={() => togglePanel(7)}>
                                <div className="question-content">
                                    <i className="fas fa-sliders"></i>
                                    <span>Can I customize the tone and style of the content?</span>
                                </div>
                                <i className={`fas ${openIndex === 7 ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                            </div>
                            {openIndex === 7 && (
                                <div className="faq-answer">
                                    Definitely! You can adjust multiple aspects including tone (professional, conversational, informative), writing style, content structure, level of detail, and voice to perfectly match your brand identity or specific platform requirements.
                                </div>
                            )}
                        </div>

                        <div className="faq-item">
                            <div className="faq-question" onClick={() => togglePanel(8)}>
                                <div className="question-content">
                                    <i className="fas fa-search"></i>
                                    <span>Is the content SEO-friendly?</span>
                                </div>
                                <i className={`fas ${openIndex === 8 ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                            </div>
                            {openIndex === 8 && (
                                <div className="faq-answer">
                                    Yes, all generated content is designed with search engine optimization in mind. The system naturally incorporates relevant keywords and follows best practices for content structure, including appropriate headings and formatting to help your content perform well in search results.
                                </div>
                            )}
                        </div>

                        <div className="faq-item">
                            <div className="faq-question" onClick={() => togglePanel(9)}>
                                <div className="question-content">
                                    <i className="fas fa-life-ring"></i>
                                    <span>Is there a refund policy?</span>
                                </div>
                                <i className={`fas ${openIndex === 9 ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                            </div>
                            {openIndex === 9 && (
                                <div className="faq-answer">
                                    Yes, we offer a comprehensive 30-day money-back guarantee. If you're not completely satisfied with YT Copycat for any reason, simply contact our support team within 30 days of purchase for a full refund with no questions asked.
                                    </div>
                                )}
                        </div>

                        <div className="faq-item">
                            <div className="faq-question" onClick={() => togglePanel(10)}>
                                <div className="question-content">
                                    <i className="fas fa-question-circle"></i>
                                    <span>Is it difficult to set up my OpenAI API key?</span>
                                </div>
                                <i className={`fas ${openIndex === 10 ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                            </div>
                            {openIndex === 10 && (
                                <div className="faq-answer">
                                    Not at all! We've made the process extremely simple. In our members area, you'll find detailed, step-by-step instructions with screenshots to guide you through setting up your OpenAI API key. Most users complete this process in less than 5 minutes. Our support team is also available if you need any assistance.
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="section final-cta-section">

                    <div className="section-header">
                        <h2>Join The Content Revolution <span className="lp-highlight">Today</span></h2>
                        <p>Stop struggling with expensive, time-consuming content creation. Join thousands of smart content creators who are leveraging YT Copycat to build their content empires.</p>
                    </div>
                    <div className="price-tag">
                        <span className="old-price">$297</span>
                        $197
                        <span className="discount-label">33% OFF</span>
                    </div>

                    <h4>For a limited time: Get YT Copycat for just $197 (33% OFF the regular price of $297)</h4>

                    <button className="button-important" onClick={handleCheckout} disabled={isProcessingCheckout}>
                        <div className="inner">
                            {isProcessingCheckout ? 'PROCESSING PAYMENT...' : 'SECURE YOUR DISCOUNT NOW'}
                        </div>
                    </button>

                </div>

                    <div className="section ebook-section">
                        <div className="ebook-container">
                            <div className="ebook-image">
                                <img src="/ebook.png" alt="Ebook preview" />
                            </div>
                            <div className="ebook-content">
                                <h2>Wait! We have something for you</h2>
                                <p>Unlock efficiency with "Productivity Unleashed". Dive into essential productivity principles and strategies to streamline your success. Claim yours and start mastering efficiency today.</p>
                                <form className="ebook-form" onSubmit={handleEbookSubmit}>
                                    <input
                                        type="email"
                                        placeholder="Your email address"
                                        name="email"
                                        required
                                    />
                                    <div className="recaptcha-container">
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            size="invisible"
                                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                        />
                                        <div className="recaptcha-terms">
                                            This site is protected by reCAPTCHA and the Google
                                            <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
                                            <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
                                        </div>
                                    </div>
                                    <LoadingButton
                                        type="submit"
                                        className="button-important"
                                        isLoading={isSubmitting}
                                    >
                                        <div className="inner">Get my book</div>
                                    </LoadingButton>
                                </form>
                            </div>
                        </div>
                    </div>
            </div>

                    <Footer />

            {/* Toast en dehors du ParallaxProvider */}
            {toast.show && (
                <Toast
                    type={toast.type}
                    message={toast.message}
                    onClose={() => setToast({ ...toast, show: false })}
                    duration={6000}
                />
            )}
        </>
    );
};

export default LandingPage;
