// App.js
import React, { useState, useContext } from 'react';
import Sidebar from './components/App/Sidebar';
import BottomMenu from './components/App/BottomMenu';
import Settings from './components/App/Settings';
import BlogGenerator from './components/App/Generator/BlogGenerator';
import TweetGenerator from './components/App/Generator/TweetGenerator';
import NewsletterGenerator from './components/App/Generator/NewsletterGenerator';
import LinkedinGenerator from './components/App/Generator/LinkedinGenerator';
import CustomGenerator from './components/App/Generator/CustomGenerator';
import SummaryGenerator from './components/App/Generator/SummaryGenerator';
import { AuthContext } from './components/Login/AuthContext';
import UpgradePrompt from './components/App/Generator/UpgradePrompt';
import './App.css';

import Generations from './components/App/Generations';

function App() {
    const [activeSection, setActiveSection] = useState('blog');
    const { isAuthenticated } = useContext(AuthContext);


    const handleSectionChange = (section) => {
        console.log('Current active section:', section);
        setActiveSection(section);
    };

    const renderActiveSection = () => {
        switch (activeSection) {
            case 'blog':
                return <BlogGenerator />;
            case 'settings':
                return <Settings/>;
            case 'generations':
                return <Generations />;
            case 'tweet':
                return <TweetGenerator/>;
            case 'newsletter':
                return <NewsletterGenerator/>;
            case 'linkedin':
                return <LinkedinGenerator/>;
            case 'custom':
                return <CustomGenerator/>;
            case 'summary':
                return <SummaryGenerator/>;
            case 'pricing':
                return <UpgradePrompt/>;
            
            default:
                return <Settings/>;
        }
    };

    return (
        <>
            <div className="container-fluid" style={{ padding: 0 }}>
                {isAuthenticated && (
                    <div className='app'>
                        <Sidebar 
                            onSelect={handleSectionChange} 
                            selected={activeSection}
                        />

                        <div className="content-section">
                            {renderActiveSection()}
                        </div>
                        <BottomMenu onSelect={handleSectionChange} selected={activeSection} />
                    </div>
                )}
            </div>
        </>
    );
}

export default App;
