import React, { useState, useEffect } from 'react';
import './UpgradePrompt.css';
import api from '../../api';
import Toast from '../../UI/Toast';

const UpgradePrompt = ({ autoCheckout = false }) => {
    const [isProcessingCheckout, setIsProcessingCheckout] = useState(false);
    const [toast, setToast] = useState({ show: false, type: '', message: '' });

    // Déclencher automatiquement le checkout si autoCheckout est true
    useEffect(() => {
        if (autoCheckout) {
            handleCheckout();
        }
    }, [autoCheckout]);

    const handleCheckout = async () => {
        try {
            setIsProcessingCheckout(true);
            
            // Définir votre identifiant de prix Stripe
            const stripePriceId = process.env.REACT_APP_LTD_PRICE_ID; // STANDALONE_PRICE_ID from config
            
            // Faire la requête à l'API pour créer une session de paiement
            const response = await api.post(`/checkout/${stripePriceId}`);
            
            // Récupérer l'URL de redirection depuis la réponse
            const checkoutUrl = response.data.checkout_url;
            
            // Rediriger vers l'URL de checkout Stripe
            window.location.href = checkoutUrl;
        } catch (error) {
            console.error('Erreur lors de la création de la session de paiement:', error);
            
            setToast({
                show: true,
                type: 'error',
                message: "An error occurred while preparing your payment. Please try again."
            });
            
            setIsProcessingCheckout(false);
        }
    };

    return (
        <div className="upgrade-prompt">
            <div className="upgrade-content">
                <h2>
                    <i className="fas fa-crown"></i>
                    {" "}Unlock Premium Content System
                </h2>
                <p className="subtitle">Transform any YouTube video into multiple high-converting content formats in seconds!</p>
                
                <div className="price-tag">
                    <span className="old-price">$297</span>
                    <span className="current-price">$197</span>
                    <span className="discount-label">33% OFF</span>
                </div>
                
                <p className="limited-offer">Limited Time Offer - Don't Miss Out!</p>
                
                <ul>
                    <li>✓ Generate unlimited content with your own API key</li>
                    <li>✓ Access to latest AI models (GPT-4o)</li>
                    <li>✓ Create blog posts, social media content and more</li>
                    <li>✓ Support for 30+ languages</li>
                    <li>✓ Save $1,000+ on content creation costs</li>
                </ul>

                <p className="value-prop">Stop struggling with expensive, time-consuming content creation. Join thousands of smart content creators leveraging YT Copycat today!</p>

                <button 
                    className="button-important" 
                    onClick={handleCheckout} 
                    disabled={isProcessingCheckout}
                >
                    <div className="inner">
                        <i className="fas fa-crown"></i>
                        {isProcessingCheckout ? 'PROCESSING PAYMENT...' : 'CLAIM YOUR DISCOUNT NOW'}
                    </div>
                </button>
            </div>
            {toast.show && (
                <Toast
                    type={toast.type}
                    message={toast.message}
                    onClose={() => setToast({ ...toast, show: false })}
                    duration={6000}
                />
            )}
        </div>
    );
};

export default UpgradePrompt; 