import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Navbar from './Navbar';
import Footer from './Footer';
import './PurchaseConfirmation.css';

const PurchaseConfirmation = () => {
    const navigate = useNavigate();

    return (
        <>
            <Helmet>
                {/* Event snippet for Website sale conversion page */}
                <script>
                    {`
                        console.log("PurchaseConfirmation");
                        gtag('event', 'conversion', {
                            'send_to': 'AW-1001697751/moJ4CKWZ_NICENfj0t0D',
                            'transaction_id': ''
                        });
                    `}
                </script>
            </Helmet>
            
            <div className="purchase-confirmation">
                <div className="confirmation-content">
                    <div className="confirmation-icon">
                        <svg viewBox="0 0 24 24" width="80" height="80">
                            <path fill="#4CAF50" d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm5.78,8.38-6.5,6.89a1,1,0,0,1-1.42.11L5.92,12.13a1,1,0,0,1-.15-1.4,1,1,0,0,1,1.41-.16l3.35,2.8,6-6.33a1,1,0,0,1,1.4-.09A1,1,0,0,1,17.78,8.38Z" />
                        </svg>
                    </div>
                    <h1>Thank You for Your Purchase!</h1>
                    <div className="divider"></div>
                    
                    <h2>Your account has been activated</h2>
                    <p>Congratulations! You now have full access to all premium features of YT Copycat.</p>

                    <div className="info-box">
                        <h3>If you're a new user:</h3>
                        <p>We've sent your login credentials to your email address. Please check your inbox (and spam folder) for an email with the subject:</p>
                        <p className="email-subject">"Welcome to YT Copycat - Your Access Details"</p>
                        <ul>
                            <li>The email contains your username and a secure password</li>
                            <li>You can use these credentials to log in immediately</li>
                            <li>We recommend changing your password after your first login</li>
                        </ul>
                    </div>

                    <div className="info-box">
                        <h3>If you already have an account:</h3>
                        <p>Your existing account has been upgraded with premium features. Simply log in with your usual credentials to access all premium features.</p>
                        <p>No need to check for an email - all changes have been applied automatically to your account.</p>
                    </div>

                    <div className="action-buttons">
                        <button 
                            className="button-important"
                            onClick={() => navigate('/app')}
                        >
                            <div className="inner">
                                Go to My Dashboard
                            </div>
                        </button>
                        <button 
                            className="button-hollow"
                            onClick={() => navigate('/login')}
                        >
                            Login to My Account
                        </button>
                    </div>

                    <div className="support-info">
                        <p>Need help? Contact our support team at <a href="mailto:contact@ytcopycat.com">contact@ytcopycat.com</a></p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PurchaseConfirmation; 