import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthContext } from './components/Login/AuthContext';
import App from './App';
import Affiliate from './components/Page/Affiliate';
import PublicSummaryTool from './components/Tools/PublicSummaryTool';
import UpgradePrompt from './components/App/Generator/UpgradePrompt';

import './styles/button.css'
import './styles/style.css'
import './styles/form.css'
import Login from './components/Login/Login';
import Signup from './components/Login/Signup';
import LoginWithToken from './components/Login/LoginWithToken';
import LandingPage from './components/Page/LandingPage';
import Legal from './components/Page/Legal';
import BlogList from './components/Blog/BlogList';
import BlogPost from './components/Blog/BlogPost';
import Contact from './components/Page/Contact';
import YTThumbnail from './components/Tools/YTThumbnail';
import PurchaseConfirmation from './components/Page/PurchaseConfirmation';
import WaitlistConfirmation from './components/Aggressive/WaitlistConfirmation';
const AppRouter = () => {
    const { isAuthenticated } = useContext(AuthContext);

    return (
        <Router>
            <Routes>
                <Route path="/blog" element={<BlogList />} />
                <Route path="/blog/:slug" element={<BlogPost />} />
                <Route path="/affiliate" element={<Affiliate />} />
                <Route path="/youtube_summary" element={<PublicSummaryTool />} />
                <Route
                    path="/"
                    element={<LandingPage />}
                />
                <Route
                    path="/app"
                    element={isAuthenticated ? <App /> : <Navigate to="/login" />}
                />
                <Route
                    path="/legal"
                    element={<Legal />}
                />
                <Route
                    path="/login"
                    element={isAuthenticated ? <Navigate to="/app" /> : <Login />}
                />
                <Route
                    path="/signup"
                    element={isAuthenticated ? <Navigate to="/app" /> : <Signup />}
                />
                <Route
                    path="/login_with_token"
                    element={isAuthenticated ? <Navigate to="/app" /> : <LoginWithToken />}
                />
                <Route path="/contact" element={<Contact />} />
                <Route path="/youtube_thumbnail_downloader" element={<YTThumbnail />} />
                <Route path="/purchase_confirmation" element={<PurchaseConfirmation />} />
                <Route path="/checkout" element={<UpgradePrompt autoCheckout={true} />} />
            </Routes>
        </Router>
    );
};

export default AppRouter;
